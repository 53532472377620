.spinner-border.btn-loader--size-lg {
  width: 1.5rem;
  height: 1.5rem;
}

.user-dropdown-button {
  width: 110px;
}

.save-button-width {
  width: 170px;
}

.transparent-button {
  background-color: transparent;
  border: 1px solid transparent; /* Ensure border is transparent initially */
  color: inherit; /* Inherits color from parent */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.transparent-button:hover {
  border-color: rgba(255, 255, 255, 0.2); /* Lightly visible borders on hover */
  color: rgba(255, 255, 255, 0.7); /* Adjust color to be slightly more visible */
}


.button-47 {
  align-items: center;
  background: #FFFFFF;
  border: 0 solid #E2E8F0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  color: #1A202C;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  height: 56px;
  justify-content: center;
  line-height: 24px;
  overflow-wrap: break-word;
  padding: 24px;
  text-decoration: none;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#17a2b8 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, #17a2b8 0 2px 4px, #17a2b8 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.btn-amber {
  background-color: #ffbf00; 
  color: white;
}

.btn-teal {
  background-color: #008080; 
  color: white;
}

.btn-teal:hover {
  background-color: #006666 !important;  /* Using !important to ensure it takes precedence */
}

.button-30-amber {
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #ffbf00 0 -3px 0 inset; /* Bootstrap Primary Blue */
}

.button-30-warning {
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #ffc107 0 -3px 0 inset; /* Bootstrap Success Green */
}

.button-30-info {
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #17a2b8 0 -3px 0 inset; /* Same as Weiter for consistency */
}

.button-30-teal {
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #008080 0 -3px 0 inset; /* Bootstrap Danger Red */
}
