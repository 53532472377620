.content-section h2 {
  color: #555;
  margin-top: 20px;
  text-align: center;
}


.data-categories li {
  margin-bottom: 10px;
}

.transcription-section {
  padding: 10px;
  margin-top: 10px;
}

.transcription-section p {
  margin-bottom: 15px;
}

.data-section {
  padding: 10px;
  margin-top: 10px;
}

.general-info-section {
  padding: 10px;
  margin-top: 10px;
}

ul.data-categories {
  margin-left: 15px;
  list-style-type: none;
  padding: 0;
}

ul.data-categories li:before {
  content: '•';
  color: #f0756b;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.audino-img {
  max-width: 100%; /* Makes sure the image is never wider than its container */
  height: auto; /* Keeps the image aspect ratio intact */
  margin-top: 20px; /* Adds some space above the image */
}