.segment-transcription-header {
    margin: 0 0 10px 0;
    border-bottom: 1px solid #f0756b;
}

.author-audio-names {
    margin: 100px 0 0 40px;
    text-align: center;
    padding: 10px 0 10px 0;
    /* box-shadow: 0 2px 4px rgba(45, 35, 66, .4), 0 7px 13px -3px rgba(45, 35, 66, .3), inset 0 -3px 0 rebeccapurple; */
    position: fixed; 
    bottom: 0; 
    left: 0; 
    width: 100%; 
    margin-bottom: 45px; 
    display: flex;
    justify-content: center;
    align-items: center;
}