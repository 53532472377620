html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
#root > div {
  height: 100%;
  margin: 0;
}

#waveform {
  width: 100%;
  height: 130px;
}

#timeline {
  width: 100%;
}

#navbarUsername {
  color: #f0756b!important;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  display: block;
  padding: 0.5rem 1rem;
}

.bottom-padding {
  padding-bottom: 60px;
}