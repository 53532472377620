.scrollable-list {
  margin-top: 10px;
  overflow-y: auto;
  max-height: 160px;
  width: 100%; /* Ensures it takes up full width of the td */
}

.scrollable-list-80 {
  max-height: 80px;

}

.scrollable-list-160 {
  max-height: 160px;
}

/*handling long audio names*/
.scrollable-list ul li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }

.audio-number {
  position: sticky; /* Fix the position of the audio-number */
  top: 10px; /* Keeps it fixed 10px from the top of the td */
  min-width: 30px;
  background-color: white; /* Ensure it's readable above list */
  z-index: 1; /* Keeps it above the list */
  margin-bottom: 10px; /* Add some space below */
  box-shadow: 0 2px 4px rgba(45, 35, 66, .4), 0 7px 13px -3px rgba(45, 35, 66, .3), inset 0 -1px 0 #f0756b;
}

.center-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%; /* Ensures full height of the td */
}